//TODO
//windowのサイズによってフォントのサイズを分ける
let cvs, ctx;
let logoCvs, logoCtx;
let textProp = {
	size: [8, 18],
	lineheight: [1, 1.2]
}
let bg = {
	cvsRatio: 1,
	texts: "",
	txtArry: [],
	posArry: [],
	logo: "",
	init: function(){
		cvs = document.getElementById("bg_canvas");
		ctx = cvs.getContext("2d");
		ctx.textBaseline = "top";
		
		logoCvs = document.getElementById("logo_canvas");
		logoCtx = logoCvs.getContext("2d");
		logoCtx.textBaseline = "top";
		
		bg.resize();
		let url = "/assets/json/counted.json";
		load.getJson(url, {}).done(function(json){
			WebFont.load({
				google: {
					families: ['Roboto Mono']
				}
			});
			checkWebFont();
			function checkWebFont(){
				if( !$html.classList.contains("wf-active") ){
					setTimeout(function(){
						checkWebFont();
					}, 33);
				}else{
					
					bg.makeHTML(json);
				}
			}
		});
	},
	resize: function(){
		bg.cvsRatio = window.devicePixelRatio;
		$("#bg_canvas, #logo_canvas").attr({
			"width": window.innerWidth*bg.cvsRatio + "px",
			"height": window.innerHeight*bg.cvsRatio + "px"
		});
		if(bg.txtArry.length > 0){
			bg.draw();
		}
	},
	makeHTML: function(json){
		let data = JSON.parse( json );
		let files = data.myFiles;
		let len = files.length;
		//テキスト作成
		for(let i = 0; i < len; i++){
			let d = files[i];
			bg.texts += d.name + " ";
		}
		//ロゴ画像のロード
		bg.logo = new Image();
		bg.logo.onload = function(){
			bg.draw();
		}
		bg.logo.src = "/assets/images/logo.svg";
		
		//resize
		var resizeFlag;
		var resizeTimer = null;
		var resizeCallback = function() {
			bg.resize();
			resizeTimer = null;
		};
		$(window).off("resize").on("resize", function(){
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	draw: function(){
		let font = 'Roboto Mono';
		if(isSmallScreen){
			logoCtx.font 	= textProp.size[0] + "px " + font;
			ctx.font 		= textProp.size[0] + "px " + font;
		}else{
			logoCtx.font 	= textProp.size[1] + "px " + font;
			ctx.font 		= textProp.size[1] + "px " + font;
		}
		
		bg.txtArry 	= bg.makeMultiLineText(bg.texts);
		//CLEAR
		ctx.clearRect(0, 0, cvs.w, cvs.h);
		logoCtx.clearRect(0, 0, cvs.w, cvs.h);
		
		//logoCtx.save();
		//ロゴ----------------------------------------------
		ctx.beginPath();
		logoCtx.beginPath();
		if(isSmallScreen){
			logoCtx.fillStyle = "rgba(255, 255, 255, .7)";
		}else{
			logoCtx.fillStyle = "rgba(255, 255, 255, .4)";
		}
		
		bg.fillText("logo", bg.txtArry, bg.logo);
		logoCtx.globalCompositeOperation = 'destination-in';
		let w, top, left;
		if(isSmallScreen){
			w 		= cvs.width*.35;
			left 	= (cvs.width - w)/2;
			top 	= cvs.height*.1;
		}else{
			w 		= cvs.width*.25;
			left 	= (cvs.width - w)/3.5;
			top 	= (cvs.height - w)/2;
		}
		logoCtx.drawImage(bg.logo, left, top, w, w);
		console.log(logoCtx);
		//logoCtx.restore();
		//ロゴ----------------------------------------------
		
		if(isSmallScreen){
			ctx.fillStyle 	= "rgba(255,255,255, .2)";
		}else{
			ctx.fillStyle 	= "rgba(255,255,255, .15)";
		}
		
		bg.fillText("text", bg.txtArry, bg.logo);
		
		logoCtx.closePath();
		ctx.closePath();
		
		if( $("body").hasClass("disable") ){
			$("body").removeClass("disable");
			anim.init();
		}
	},
	makeMultiLineText: function(text){
		let strArry = [];
		let len = text.length;
		let tmp = "";
		
		for(var i = 0; i < len; i++){
			let c = text.charAt(i);
			if( ctx.measureText(tmp + c).width*bg.cvsRatio <= cvs.width*bg.cvsRatio ){
				tmp += c;
			}else{
				strArry.push(tmp);
				tmp = c;
			}
		}
		return strArry;
	},
	fillText: function(type, txtArry){
		let n 			= txtArry.length;
		let text 		= ctx.measureText( txtArry[0].charAt(0) );
		bg.posArry = [];
		let lineHeight;
		if(isSmallScreen){
			lineHeight 	= ( text.actualBoundingBoxAscent + text.actualBoundingBoxDescent )*bg.cvsRatio*textProp.lineheight[0];
		}else{
			lineHeight 	= ( text.actualBoundingBoxAscent + text.actualBoundingBoxDescent )*bg.cvsRatio*textProp.lineheight[1];
		}
		
		let maxLine 	= Math.floor( (cvs.height) / lineHeight );
		if(type === "logo"){
			logoCtx.textBaseline = "top";
			//console.log(n, lineHeight, txtArry);
			for(var i = 0; i < n; i++){
				logoCtx.fillText(txtArry[i], 0, lineHeight*i);
				bg.posArry.push(lineHeight*i);
			}
		}else{
			ctx.textBaseline = "top";
			//console.log(n, lineHeight, txtArry);
			for(var i = 0; i < n; i++){
				ctx.fillText(txtArry[i], 0, lineHeight*i);
				bg.posArry.push(lineHeight*i);
			}
		}
	}
}
let animTimer;
let txts = [];
let flags = [];//既に光ったか
let anim = {
	rowLen: 0,
	txtLen: 0,
	current: 0,
	start : 0,
	txtCount: 12,
	color: "",
	isFirst: true,
	init: function(){
		let baseText = ctx.measureText( bg.txtArry[anim.current][0] ),
			lineHeight;
		if(isSmallScreen){
			lineHeight 	= ( baseText.actualBoundingBoxAscent + baseText.actualBoundingBoxDescent )*bg.cvsRatio*textProp.lineheight[0];
		}else{
			lineHeight 	= ( baseText.actualBoundingBoxAscent + baseText.actualBoundingBoxDescent )*bg.cvsRatio*textProp.lineheight[1];
		}
		
		anim.rowLen = Math.floor(cvs.height/lineHeight);
		//console.log(bg.txtArry);
		anim.selectLine();
	},
	selectLine: function(){
		anim.current = Math.floor( Math.random()*anim.rowLen );
		//anim.current = 1;
		anim.txtLen = bg.txtArry[anim.current].length;
		anim.start = 0; //文字のスタート位置
		let date = new Date();
		let time = date.getSeconds();
		anim.color = time*6;
		txts = [];
		flags = [];
		for( let i = 0; i < anim.txtLen; i++){
			txts.push(0);
			flags.push(1);
		}
		console.log(anim.color, txts);
		if(anim.isFirst){
			anim.isFirst = false;
			anim.update();
		}
	},
	update: function(){
		let left = 0;
		let lineHeight;
		//font setting
		ctx.textBaseline = "top";
		if(isSmallScreen){
			ctx.font 	= textProp.size[0] + "px Roboto Mono";
		}else{
			ctx.font 	= textProp.size[1] + "px Roboto Mono";
		}
		let baseText = ctx.measureText( bg.txtArry[anim.current][0] );
		
		let top = bg.posArry[anim.current];
		let h = bg.posArry[anim.current+1] - top;
		//console.log(h);
		ctx.clearRect(0, top, cvs.width, h );
		//console.log(top, lineHeight)
		
		for( let i = 0; i < anim.txtLen; i++){
			ctx.fillStyle 	= `hsla(${anim.color}, 20%, 50%, ${txts[i]})`;
			if(i < anim.start + anim.txtCount){
				if(txts[i] === 0 && flags[i] === 1){
					flags[i] = 0;
					txts[i] = 1;
				}
				txts[i] = txts[i]*.85;
				if(txts[i] < 0.15){
					txts[i] = 0.15;
					ctx.fillStyle 	= `rgba(255,255,255, ${txts[i]})`;
				}else{
					
				}
			}else{
				ctx.fillStyle 	= `rgba(255, 255, 255, .15)`;
			}
			
			//ctx.fillStyle 	= `rgba(255, 255, 255, ${txts[i]})`;
			
			let currentTxt = bg.txtArry[anim.current][i];
			let text = ctx.measureText( currentTxt );
			ctx.fillText(currentTxt, left, top);
			left += text.width;
		}
		if(txts[anim.start] <= 0.3){
			anim.start += 1;
		}
		if(anim.start === anim.txtLen - 1){
			
			setTimeout(function(){
				anim.selectLine();
			}, Math.floor( Math.random()*5000) );
		}
		//console.log(txts);
		animTimer = window.requestAnimationFrame(anim.update);
	}
}