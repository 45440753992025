var isPage = "";
let Animate = {
	top: 0,
	show: 0
}
let animations = [];
var common = {
	beforeW: 0,
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		bg.init();
		common.resize();
		common.nav();
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	nav: ()=>{
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		if (window.matchMedia('all and (min-width : 769px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		addressbarH();
		
		
		animations = [];
		$(".a").each(function(){
			let _a 	= object(Animate);
			_a.top 	= Math.floor( $(this).offset().top );
			_a.show = Math.floor( _a.top + window.innerHeight/3 );
			animations.push(_a);
		});
		
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
		function addressbarH(){
			if(common.beforeW != winW){
				// 100vh、アドレスバーが出てる時に対応
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			}
		}
		common.beforeW = winW;
	},
	makeIO: function(){
		for(var i = 0; i < ioArry.length; i++){
			let option = {
				root 		: null,
				rootMargin 	: ioArry[i].margin,
				threshold 	: ioArry[i].threshold
			}
			let element = $(ioArry[i].target)
			const io = new IntersectionObserver(ioArry[i].change, option);
			$.each(element, function(index, el){
				io.observe(el);
			});
		}
	},
	scrollToPosition: (posY, duration) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic";
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: duration,
			"easing" 	: ease,
			"complete"	: function(){
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height(),
			alen = animations.length;
		
		for(var i = 0; i < alen; i++){
			if(btmD >= animations[i].show){
				activeAnimate(i);
			}
		}
		function activeAnimate(i){
			let $a = $(".a").eq(i);
			if( !$a.hasClass("animate") ){
				$a.addClass("animate");
				setTimeout(function(){
					$a.addClass("animated");
				}, 1000);
			}
		}
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});